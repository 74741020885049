import React from "react";

const Content3 = ({ className, ...rest }) => {
  return (
    <>   
    <div className={className} {...rest}>
      <div className="container position-static">
        <div className="row pt-20 pb-0 pt-lg-20 pb-lg-0 align-items-center justify-content-center position-static">
          {/* Right Image */}
          <div className="col-xl-7 col-lg-6 order-lg-2 order-1">
            {/* content-2 start */}
            <div className="l5-content-gallery-img" data-aos="zoom-in-up" data-aos-once="true" data-aos-delay="750">
              {/* content img start */}
              <div className="d-xs-flex ml-lg-15 mx-xl-n3">
                <div className="d-flex flex-column w-100">
                  {/* single image */}
                  <div className="digma-image mt-10" data-imageid="E8503EBB-E1A0-4C58-9C83-D538600C3A9E"><img className="w-100" alt="Digma Intreactive" src="https://digmacustomers.blob.core.windows.net/4fe433c9-fb0f-47d4-9f59-37f1909ec6b8/149f86b9-723e-4cdb-8b6d-348ceb9cfd44.png" /></div>
                  {/* single image end */}
                </div>
              </div>
              {/* abs-content end */}
            </div>
            {/* content-2 end */}
          </div>
          {/* End Right Image */}
          <div className="col-xl-5 col-lg-6 order-lg-1 order-2">
            <div className="light-mode-texts mt-0 mt-lg-0 pr-xs-15 pr-sm-14 pr-md-13 pr-lg-0 pl-lg-15">
              <h1
                className="font-size-10 mb-2 letter-spacing-n83"
              >
                Bring your <br /><span className="Images">images</span> to life
              </h1>
              <p
                className="font-size-5 mb-0"
              >
                Make your images interactive by adding music, video, product carousels, contact forms and more to any image using our plethora of plugins. 
              </p>
              <div className="mt-9 mt-lg-12">
                <a href="https://app.digma.io?mode=login" className="btn bg-image text-white btn-xl h-55 rounded-5 ">try for free</a>                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    
    </>
  );
};

export default Content3;
