import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink"

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row pt-10 align-items-center justify-content-center">
          <div
            className="col-xl-5 col-lg-5 col-xs-12"
            data-aos="fade-right"
            data-aos-delay={100}
          >
            <div className="mt-5 mt-lg-0">
              <div className="img-2">
              <div class="digma-image" data-imageid="715FA8EF-D3A2-4009-B79F-B652B2FAD245"><img alt="Fais" src="https://digmacustomers.blob.core.windows.net/4fe433c9-fb0f-47d4-9f59-37f1909ec6b8/18c8e4ef-fb5f-444b-97b1-02bb9be243d0.webp" /></div>                
              {/* <div class="digma-image" data-imageid="BB6AAFC9-E8BF-499D-A649-BECCA9BACBB8"><img alt="Elf" className='w-100' src="https://digmacustomers.blob.core.windows.net/4fe433c9-fb0f-47d4-9f59-37f1909ec6b8/cac7b83c-4c0e-4d65-a9fd-1cc34c203972.webp" /></div> */}
              </div>
            </div>
          </div>
          <div
            className="col-xl-5 col-lg-5 col-xs-12"
            data-aos="fade-left"
            data-aos-delay={100}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h2 className="font-size-10 mb-2">
                Level up your content.
              </h2>
              <p className="font-size-6 mb-5">
              Use Digma as your affiliate marketing promotion tool kit.
              </p>
              <p>Interactive images can be a great callout. They are intriguing and guaranteed to increase your click through rate.</p>
              <div className="mt-9">
                <AniLink className="btn bg-image text-white btn-xl h-55 rounded-5" paintDrip hex="#ff9b04" to="/affiliate-marketing-promo-tool">
                  Learn More
                </AniLink>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
