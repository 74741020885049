import React from 'react';
import PageWrapper from '../components/PageWrapper';
import HeroNew from '../sections/interactive-images/Content3';
import Pricing from '../sections/home4/Pricing';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import WaveReverse from '../sections/common/WaveReverse';
import Commit from '../sections/common/Commit';
import SEO from '../components/SEO';
import metaImg from '../assets/image/metaimg/meta-images.jpg';
import FeatureOne from '../sections/interactive-images/FeatureOne';
import FeatureTwo from '../sections/interactive-images/FeatureTwo';
import FeatureThree from '../sections/interactive-images/FeatureThree';
const Demo = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-left',
          headerFluid: false,
          footerStyle: 'digma',
          version: 'images',
        }}
      >
        <SEO
          title="Interactive Images - Bring your images to life"
          description="Make your images interactive by adding hotspots with music, video, product carousels, contact forms and more to any image using our plethora of plugins."
          image={metaImg}
        />
        <HeroNew />
        {/* <div className='w-80 mobilefull w-s-100 m-auto pt-10 row text-center'>
          <h2 className="w-100 text-center">Make Your Affiliate Marketing More Engaging</h2>
          <div className='col-xl-6 col-lg-6 col-xs-12'>
            <div className='text-center m-5 rounded-5'>
              <h2 className='h3 w-100 Images text-center'>Turn this </h2>
                <img src={Elf} className="w-100" alt="No interactive image" />
            </div>
          </div>
          <div className='col-xl-6 col-lg-6 col-xs-12'>
            <div className='text-center m-5 rounded-5'>
              <h2 className='h3 w-100 Images text-center'>Into this...</h2>
              <div class="digma-image" data-imageid="BB6AAFC9-E8BF-499D-A649-BECCA9BACBB8"><img alt="Elf" className='w-100' src="https://digmacustomers.blob.core.windows.net/4fe433c9-fb0f-47d4-9f59-37f1909ec6b8/cac7b83c-4c0e-4d65-a9fd-1cc34c203972.webp" /></div>
            </div>
          </div>                         
		  	</div> */}
        {/* <InteractiveImagesRoll   /> */}
        <WaveReverse background="#fff" color="#F7F9FC" />
        <FeatureThree className="grey pt-0 pb-0" />
        <WaveReverse background="#F7F9FC" color="#fff" />
        <FeatureTwo className="pt-0 pb-0" />
        <WaveReverse background="#fff" color="#F7F9FC" />
        <FeatureOne className="grey pt-0" />
        <WaveReverse background="#F7F9FC" color="#fff" />
        <Pricing />
        <div className="d-none d-md-block text-center pb-10 pt-5">
          <AniLink
            className="font-size-7"
            paintDrip
            hex="#2196f3"
            to="/pricing"
          >
            View full pricing details and plan comparison
          </AniLink>
        </div>
        <div style={{ overflow: 'hidden', background: '#f7f9fc' }}>
          <Commit />
        </div>
        

      </PageWrapper>
    </>
  );
};
export default Demo;
