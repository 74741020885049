import React from "react";
import plugins from "../../assets/video/plugins.mp4"

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row mt-10 align-items-center justify-content-center">
          <div
            className="order-lg-1 order-2 col-xl-5 col-lg-5 col-xs-12"
            data-aos="fade-right"
            data-aos-delay={100}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h2 className="font-size-10 mb-2">
                Plugins
              </h2>
              <p className="font-size-6 mb-0">
              We have an ever expanding library of plugins for you to add to your interactive images. If you don't see one you want or would like for yourself or clients, ask us and we will make it for you.
              </p>
              <table className="mt-5 text-black" cellPadding="5">
              <tr>
                <td><i className="fa fa-check mr-4 bg-image border-0 text-white" /></td>
                <td>Fully customisable</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-image border-0 text-white" /></td>
                <td>Add videos, music, products and more</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-image border-0 text-white" /></td>
                <td>Multiple Hotspot icons and effects</td>
              </tr>               
              <tr>
                <td><i className="fa fa-check mr-4 bg-image border-0 text-white" /></td>
                <td>Regular plugin updates</td>
              </tr>                                                       
            </table>               
              <div className="mt-9">
                <a className="btn bg-image text-white btn-xl h-55 rounded-5" href="https://app.digma.io?mode=login/">
                try for free
                </a>
               </div>
            </div>
          </div>
          <div
            className="order-lg-2 order-1 col-xl-7 col-lg-7 col-xs-12"
            data-aos="fade-left"
            data-aos-delay={100}
          >
            <div className="mt-lg-0">
              <div className="img-2">
                <video className="w-100" muted loop autoPlay src={plugins}></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
